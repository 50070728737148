const BOTTOM_MENU = Object.freeze({
    HOME:'Home',
    SEARCH_PLACE:'SearchPlace',
    CASH:'Cash',
    MY_PAGE:'MyPage'
})

const PAGES = Object.freeze({
    LOGIN:{ // 로그인
        name:'Login', // 라우팅이름
        file:'Login', // 파일명
        path:'/', // 라우팅 경우
        menu: null, // 소속 바텀메뉴
        layoutOptions:{
            appBarShow: false, // 앱바유무
            appBarBackground: null, // 앱바 배경색
            goBackBtnShow:false, // 뒤로가기 버튼 유무
            appBarBtnColor: null, // 앱바 버튼 색(뒤로가기, 우측버튼 모두)
            additionalBtn: null, // 우측 추가적인 버튼(컴포넌트명)
            mainBackground: 'backgroundColor', // 메인 배경색
            bottomNaviShow: false, //바텀바 유무
            bottomNaviTopCornerColor: null // 바텀바 상단 좌우측 모서리 색
        }
    },
    FIND_ACCOUNT:{ // 아이디 비번 찾기
        name:'FindAccount', // 라우팅이름
        file:'FindAccount', // 파일명
        path:'/findaccount', // 라우팅 경우
        menu: null, // 소속 바텀메뉴
        layoutOptions:{
            appBarShow: true, // 앱바유무
            appBarBackground: 'backgroundColor', // 앱바 배경색
            goBackBtnShow: true, // 뒤로가기 버튼 유무
            appBarBtnColor: 'popularUseColor', // 앱바 버튼 색(뒤로가기, 우측버튼 모두)
            additionalBtn: null, // 우측 추가적인 버튼(컴포넌트명)
            mainBackground: 'backgroundColor', // 메인 배경색
            bottomNaviShow: false, //바텀바 유무
            bottomNaviTopCornerColor: null // 바텀바 상단 좌우측 모서리 색
        }
    },
    SIGN_UP:{ // 회원가입
        name:'SignUp', // 라우팅이름
        file:'SignUp', // 파일명
        path:'/signup', // 라우팅 경우
        menu: null, // 소속 바텀메뉴
        layoutOptions:{
            appBarShow: true, // 앱바유무
            appBarBackground: 'backgroundColor', // 앱바 배경색
            goBackBtnShow: true, // 뒤로가기 버튼 유무
            appBarBtnColor: 'popuplarUseColor', // 앱바 버튼 색(뒤로가기, 우측버튼 모두)
            additionalBtn: null, // 우측 추가적인 버튼(컴포넌트명)
            mainBackground: 'backgroundColor', // 메인 배경색
            bottomNaviShow: false, //바텀바 유무
            bottomNaviTopCornerColor: null // 바텀바 상단 좌우측 모서리 색
        }
    },
    HOME:{ // 메인 홈화면
        name:'Home', // 라우팅이름
        file:'Home', // 파일명
        path:'/home', // 라우팅 경우
        menu: BOTTOM_MENU.HOME, // 소속 바텀메뉴
        layoutOptions:{
            appBarShow: true, // 앱바유무
            appBarBackground: 'mainColor', // 앱바 배경색
            goBackBtnShow: false, // 뒤로가기 버튼 유무
            appBarBtnColor: 'backgroundColor', // 앱바 버튼 색(뒤로가기, 우측버튼 모두)
            additionalBtn: 'Bell', // 우측 추가적인 버튼(컴포넌트명)
            mainBackground: 'backgroundColor', // 메인 배경색
            bottomNaviShow: true, //바텀바 유무
            bottomNaviTopCornerColor: 'backgroundColor' // 바텀바 상단 좌우측 모서리 색
        }
    },
    SEARCH_PLACE:{ // 충전소찾기
        name:'SearchPlace', // 라우팅이름
        file:'SearchPlace', // 파일명
        path:'/searchplace', // 라우팅 경우
        menu: BOTTOM_MENU.SEARCH_PLACE, // 소속 바텀메뉴
        layoutOptions:{
            appBarShow: true, // 앱바유무
            appBarBackground: 'mainColor', // 앱바 배경색
            goBackBtnShow: false, // 뒤로가기 버튼 유무
            appBarBtnColor: null, // 앱바 버튼 색(뒤로가기, 우측버튼 모두)
            additionalBtn: null, // 우측 추가적인 버튼(컴포넌트명)
            mainBackground: 'mainColor', // 메인 배경색
            bottomNaviShow: true, //바텀바 유무
            bottomNaviTopCornerColor: 'subBackgroundColor' // 바텀바 상단 좌우측 모서리 색
        }
    },
    CASH:{ // 캐시페이지
        name:'Cash', // 라우팅이름
        file:'Cash', // 파일명
        path:'/cash', // 라우팅 경우
        menu: BOTTOM_MENU.CASH, // 소속 바텀메뉴
        layoutOptions:{
            appBarShow: true, // 앱바유무
            appBarBackground: 'mainColor', // 앱바 배경색
            goBackBtnShow: false, // 뒤로가기 버튼 유무
            appBarBtnColor: null, // 앱바 버튼 색(뒤로가기, 우측버튼 모두)
            additionalBtn: null, // 우측 추가적인 버튼(컴포넌트명)
            mainBackground: 'backgroundColor', // 메인 배경색
            bottomNaviShow: true, //바텀바 유무
            bottomNaviTopCornerColor: 'backgroundColor' // 바텀바 상단 좌우측 모서리 색
        }
    },
    MY_PAGE:{ // 마이페이지
        name:'MyPage', // 라우팅이름
        file:'MyPage', // 파일명
        path:'/mypage', // 라우팅 경우
        menu: BOTTOM_MENU.MY_PAGE, // 소속 바텀메뉴
        layoutOptions:{
            appBarShow: true, // 앱바유무
            appBarBackground: 'mainColor', // 앱바 배경색
            goBackBtnShow: false, // 뒤로가기 버튼 유무
            appBarBtnColor: null, // 앱바 버튼 색(뒤로가기, 우측버튼 모두)
            additionalBtn: null, // 우측 추가적인 버튼(컴포넌트명)
            mainBackground: 'backgroundColor', // 메인 배경색
            bottomNaviShow: true, //바텀바 유무
            bottomNaviTopCornerColor: 'backgroundColor' // 바텀바 상단 좌우측 모서리 색
        }
    },
    PLACE_INFO:{ // 충전소정보
        name:'PlaceInfo', // 라우팅이름
        file:'PlaceInfo', // 파일명
        path:'/placeinfo/:place_id', // 라우팅 경우
        menu: BOTTOM_MENU.SEARCH_PLACE, // 소속 바텀메뉴
        layoutOptions:{
            appBarShow: true, // 앱바유무
            appBarBackground: 'mainColor', // 앱바 배경색
            goBackBtnShow: true, // 뒤로가기 버튼 유무
            appBarBtnColor: 'backgroundColor', // 앱바 버튼 색(뒤로가기, 우측버튼 모두)
            additionalBtn:  null, // 우측 추가적인 버튼(컴포넌트명)
            mainBackground: 'mainColor', // 메인 배경색
            bottomNaviShow: true, //바텀바 유무
            bottomNaviTopCornerColor: 'backgroundColor' // 바텀바 상단 좌우측 모서리 색
        }
    },
    EVSE_INFO:{ // 충전기 정보
        name:'EvseInfo', // 라우팅이름
        file:'charging/EvseInfo', // 파일명
        path:'/evseinfo/:evse_id', // 라우팅 경우
        menu: BOTTOM_MENU.SEARCH_PLACE, // 소속 바텀메뉴
        layoutOptions:{
            appBarShow: true, // 앱바유무
            appBarBackground: 'mainColor', // 앱바 배경색
            goBackBtnShow: true, // 뒤로가기 버튼 유무
            appBarBtnColor: 'backgroundColor', // 앱바 버튼 색(뒤로가기, 우측버튼 모두)
            additionalBtn: 'Broken', // 우측 추가적인 버튼(컴포넌트명)
            mainBackground: 'mainColor', // 메인 배경색
            bottomNaviShow: false, //바텀바 유무
            bottomNaviTopCornerColor: null // 바텀바 상단 좌우측 모서리 색
        }
    },
    SELECT_PAY_METHOD:{ // 결제방법선택
        name:'SelectPayMethod', // 라우팅이름
        file:'charging/SelectPayMethod', // 파일명
        path:'/selectpaymethod/:evse_id', // 라우팅 경우
        menu: BOTTOM_MENU.SEARCH_PLACE, // 소속 바텀메뉴
        layoutOptions:{
            appBarShow: true, // 앱바유무
            appBarBackground: 'mainColor', // 앱바 배경색
            goBackBtnShow: true, // 뒤로가기 버튼 유무
            appBarBtnColor: 'backgroundColor', // 앱바 버튼 색(뒤로가기, 우측버튼 모두)
            additionalBtn: null, // 우측 추가적인 버튼(컴포넌트명)
            mainBackground: 'mainColor', // 메인 배경색
            bottomNaviShow: false, //바텀바 유무
            bottomNaviTopCornerColor: null // 바텀바 상단 좌우측 모서리 색
        }
    },
    SELECT_CREDIT_CARD:{ // 결제카드 선택
        name:'SelectCreditCard', // 라우팅이름
        file:'charging/SelectCreditCard', // 파일명
        path:'/selectcreditcard/:evse_id&:authType', // 라우팅 경우
        menu: BOTTOM_MENU.SEARCH_PLACE, // 소속 바텀메뉴
        layoutOptions:{
            appBarShow: true, // 앱바유무
            appBarBackground: 'mainColor', // 앱바 배경색
            goBackBtnShow: true, // 뒤로가기 버튼 유무
            appBarBtnColor: 'backgroundColor', // 앱바 버튼 색(뒤로가기, 우측버튼 모두)
            additionalBtn: null, // 우측 추가적인 버튼(컴포넌트명)
            mainBackground: 'mainColor', // 메인 배경색
            bottomNaviShow: false, //바텀바 유무
            bottomNaviTopCornerColor: null // 바텀바 상단 좌우측 모서리 색
        }
    },
    SELECT_CHARGING_AMOUNT:{ // 결제금액 선택
        name:'SelectChargingAmount', // 라우팅이름
        file:'charging/SelectChargingAmount', // 파일명
        path:'/selectchargingamount/:evse_id&:authType&:card_id?', // 라우팅 경우
        menu: BOTTOM_MENU.SEARCH_PLACE, // 소속 바텀메뉴
        layoutOptions:{
            appBarShow: true, // 앱바유무
            appBarBackground: 'mainColor', // 앱바 배경색
            goBackBtnShow: true, // 뒤로가기 버튼 유무
            appBarBtnColor: 'backgroundColor', // 앱바 버튼 색(뒤로가기, 우측버튼 모두)
            additionalBtn: null, // 우측 추가적인 버튼(컴포넌트명)
            mainBackground: 'mainColor', // 메인 배경색
            bottomNaviShow: false, //바텀바 유무
            bottomNaviTopCornerColor: null // 바텀바 상단 좌우측 모서리 색
        }
    },
    CHARGING_INFO:{ // 충전정보
        name:'ChargingInfo', // 라우팅이름
        file:'charging/ChargingInfo', // 파일명
        path:'/charginginfo/:transaction_id', // 라우팅 경우
        menu: BOTTOM_MENU.SEARCH_PLACE, // 소속 바텀메뉴
        layoutOptions:{
            appBarShow: true, // 앱바유무
            appBarBackground: 'mainColor', // 앱바 배경색
            goBackBtnShow: false, // 뒤로가기 버튼 유무
            appBarBtnColor: 'backgroundColor', // 앱바 버튼 색(뒤로가기, 우측버튼 모두)
            additionalBtn: 'Home', // 우측 추가적인 버튼(컴포넌트명)
            mainBackground: 'mainColor', // 메인 배경색
            bottomNaviShow: false, //바텀바 유무
            bottomNaviTopCornerColor: null // 바텀바 상단 좌우측 모서리 색
        }
    },
    CHARGING_FAILED:{ // 충전중 중단시 안내 페이지
        name:'ChargingFailed', // 라우팅이름
        file:'charging/ChargingFailed', // 파일명
        path:'/chargingfailed/:transaction_id&:evseNum', // 라우팅 경우
        menu: BOTTOM_MENU.SEARCH_PLACE, // 소속 바텀메뉴
        layoutOptions:{
            appBarShow: true, // 앱바유무
            appBarBackground: 'mainColor', // 앱바 배경색
            goBackBtnShow: true, // 뒤로가기 버튼 유무
            appBarBtnColor: 'backgroundColor', // 앱바 버튼 색(뒤로가기, 우측버튼 모두)
            additionalBtn: null, // 우측 추가적인 버튼(컴포넌트명)
            mainBackground: 'mainColor', // 메인 배경색
            bottomNaviShow: false, //바텀바 유무
            bottomNaviTopCornerColor: null // 바텀바 상단 좌우측 모서리 색
        }
    },
    CASH_SAVE:{ // 캐시 적립페이지
        name:'CashSave', // 라우팅이름
        file:'CashSave', // 파일명
        path:'/cashsave', // 라우팅 경우
        menu: BOTTOM_MENU.CASH, // 소속 바텀메뉴
        layoutOptions:{
            appBarShow: true, // 앱바유무
            appBarBackground: 'mainColor', // 앱바 배경색
            goBackBtnShow: true, // 뒤로가기 버튼 유무
            appBarBtnColor: 'backgroundColor', // 앱바 버튼 색(뒤로가기, 우측버튼 모두)
            additionalBtn: null, // 우측 추가적인 버튼(컴포넌트명)
            mainBackground: 'mainColor', // 메인 배경색
            bottomNaviShow: false, //바텀바 유무
            bottomNaviTopCornerColor: null // 바텀바 상단 좌우측 모서리 색
        }
    },
    CASH_REFUND:{ // 캐시 환불페이지
        name:'CashRefund', // 라우팅이름
        file:'CashRefund', // 파일명
        path:'/cashrefund', // 라우팅 경우
        menu: BOTTOM_MENU.CASH, // 소속 바텀메뉴
        layoutOptions:{
            appBarShow: true, // 앱바유무
            appBarBackground: 'mainColor', // 앱바 배경색
            goBackBtnShow: true, // 뒤로가기 버튼 유무
            appBarBtnColor: 'backgroundColor', // 앱바 버튼 색(뒤로가기, 우측버튼 모두)
            additionalBtn: null, // 우측 추가적인 버튼(컴포넌트명)
            mainBackground: 'mainColor', // 메인 배경색
            bottomNaviShow: false, //바텀바 유무
            bottomNaviTopCornerColor: null // 바텀바 상단 좌우측 모서리 색
        }
    },
    CASH_GIFT:{ // 캐시 선물
        name:'CashGift', // 라우팅이름
        file:'CashGift', // 파일명
        path:'/cashgift', // 라우팅 경우
        menu: BOTTOM_MENU.CASH, // 소속 바텀메뉴
        layoutOptions:{
            appBarShow: true, // 앱바유무
            appBarBackground: 'mainColor', // 앱바 배경색
            goBackBtnShow: true, // 뒤로가기 버튼 유무
            appBarBtnColor: 'backgroundColor', // 앱바 버튼 색(뒤로가기, 우측버튼 모두)
            additionalBtn: null, // 우측 추가적인 버튼(컴포넌트명)
            mainBackground: 'mainColor', // 메인 배경색
            bottomNaviShow: false, //바텀바 유무
            bottomNaviTopCornerColor: null // 바텀바 상단 좌우측 모서리 색
        }
    },
    CREDIT_CARD_LIST:{ // 결제카드 리스트페이지
        name:'CreditCardList', // 라우팅이름
        file:'CreditCardList', // 파일명
        path:'/creditcardlist', // 라우팅 경우
        menu: BOTTOM_MENU.MY_PAGE, // 소속 바텀메뉴
        layoutOptions:{
            appBarShow: true, // 앱바유무
            appBarBackground: 'mainColor', // 앱바 배경색
            goBackBtnShow: true, // 뒤로가기 버튼 유무
            appBarBtnColor: 'backgroundColor', // 앱바 버튼 색(뒤로가기, 우측버튼 모두)
            additionalBtn: 'Home', // 우측 추가적인 버튼(컴포넌트명)
            mainBackground: 'mainColor', // 메인 배경색
            bottomNaviShow: false, //바텀바 유무
            bottomNaviTopCornerColor: null // 바텀바 상단 좌우측 모서리 색
        }
    },
    CREDIT_CARD_REGI:{ // 결제카드 등록페이지
        name:'CreditCardRegi', // 라우팅이름
        file:'CreditCardRegi', // 파일명
        path:'/creditcardregi', // 라우팅 경우
        menu: BOTTOM_MENU.MY_PAGE, // 소속 바텀메뉴
        layoutOptions:{
            appBarShow: true, // 앱바유무
            appBarBackground: 'mainColor', // 앱바 배경색
            goBackBtnShow: true, // 뒤로가기 버튼 유무
            appBarBtnColor: 'backgroundColor', // 앱바 버튼 색(뒤로가기, 우측버튼 모두)
            additionalBtn: null, // 우측 추가적인 버튼(컴포넌트명)
            mainBackground: 'mainColor', // 메인 배경색
            bottomNaviShow: false, //바텀바 유무
            bottomNaviTopCornerColor: null // 바텀바 상단 좌우측 모서리 색
        }
    },
    CHARGING_HISTORY_PERIOD:{ // 충전내역 검색할 기간 선택 페이지
        name:'ChargingHistoryPeriod', // 라우팅이름
        file:'ChargingHistoryPeriod', // 파일명
        path:'/charginghistoryperiod', // 라우팅 경우
        menu: BOTTOM_MENU.MY_PAGE, // 소속 바텀메뉴
        layoutOptions:{
            appBarShow: true, // 앱바유무
            appBarBackground: 'mainColor', // 앱바 배경색
            goBackBtnShow: true, // 뒤로가기 버튼 유무
            appBarBtnColor: 'backgroundColor', // 앱바 버튼 색(뒤로가기, 우측버튼 모두)
            additionalBtn: 'Home', // 우측 추가적인 버튼(컴포넌트명)
            mainBackground: 'mainColor', // 메인 배경색
            bottomNaviShow: false, //바텀바 유무
            bottomNaviTopCornerColor: null // 바텀바 상단 좌우측 모서리 색
        }
    },
    CHARGING_HISTORY_LIST:{ // 충전내역
        name:'ChargingHistoryList', // 라우팅이름
        file:'ChargingHistoryList', // 파일명
        path:'/charginghistorylist/:start&:end', // 라우팅 경우
        menu: BOTTOM_MENU.MY_PAGE, // 소속 바텀메뉴
        layoutOptions:{
            appBarShow: true, // 앱바유무
            appBarBackground: 'mainColor', // 앱바 배경색
            goBackBtnShow: true, // 뒤로가기 버튼 유무
            appBarBtnColor: 'backgroundColor', // 앱바 버튼 색(뒤로가기, 우측버튼 모두)
            additionalBtn: 'Home', // 우측 추가적인 버튼(컴포넌트명)
            mainBackground: 'mainColor', // 메인 배경색
            bottomNaviShow: false, //바텀바 유무
            bottomNaviTopCornerColor: null // 바텀바 상단 좌우측 모서리 색
        }
    },
    BROKEN_REGI:{ // 고장신고등록
        name:'BrokenRegi', // 라우팅이름
        file:'BrokenRegi', // 파일명
        path:'/brokenregi/:evseNum?', // 라우팅 경우
        menu: BOTTOM_MENU.MY_PAGE, // 소속 바텀메뉴
        layoutOptions:{
            appBarShow: true, // 앱바유무
            appBarBackground: 'mainColor', // 앱바 배경색
            goBackBtnShow: true, // 뒤로가기 버튼 유무
            appBarBtnColor: 'backgroundColor', // 앱바 버튼 색(뒤로가기, 우측버튼 모두)
            additionalBtn: null, // 우측 추가적인 버튼(컴포넌트명)
            mainBackground: 'mainColor', // 메인 배경색
            bottomNaviShow: false, //바텀바 유무
            bottomNaviTopCornerColor: null // 바텀바 상단 좌우측 모서리 색
        }
    },
    BROKEN_LIST:{ // 고장신고 내역
        name:'BrokenList', // 라우팅이름
        file:'BrokenList', // 파일명
        path:'/brokenlist', // 라우팅 경우
        menu: BOTTOM_MENU.MY_PAGE, // 소속 바텀메뉴
        layoutOptions:{
            appBarShow: true, // 앱바유무
            appBarBackground: 'mainColor', // 앱바 배경색
            goBackBtnShow: true, // 뒤로가기 버튼 유무
            appBarBtnColor: 'backgroundColor', // 앱바 버튼 색(뒤로가기, 우측버튼 모두)
            additionalBtn: null, // 우측 추가적인 버튼(컴포넌트명)
            mainBackground: 'mainColor', // 메인 배경색
            bottomNaviShow: false, //바텀바 유무
            bottomNaviTopCornerColor: null // 바텀바 상단 좌우측 모서리 색
        }
    },
    BOARD_LIST:{ // 게시물 리스트
        name:'BoardList', // 라우팅이름
        file:'BoardList', // 파일명
        path:'/boardlist/:type/:detail_id?', // 라우팅 경우
        menu: BOTTOM_MENU.MY_PAGE, // 소속 바텀메뉴
        layoutOptions:{
            appBarShow: true, // 앱바유무
            appBarBackground: 'mainColor', // 앱바 배경색
            goBackBtnShow: true, // 뒤로가기 버튼 유무
            appBarBtnColor: 'backgroundColor', // 앱바 버튼 색(뒤로가기, 우측버튼 모두)
            additionalBtn: 'Home', // 우측 추가적인 버튼(컴포넌트명)
            mainBackground: 'mainColor', // 메인 배경색
            bottomNaviShow: false, //바텀바 유무
            bottomNaviTopCornerColor: null // 바텀바 상단 좌우측 모서리 색
        }
    },
    MY_INFO_CHANGE:{ // 내정보 수정
        name:'MyInfoChange', // 라우팅이름
        file:'MyInfoChange', // 파일명
        path:'/myinfochange/:type', // 라우팅 경우
        menu: BOTTOM_MENU.MY_PAGE, // 소속 바텀메뉴
        layoutOptions:{
            appBarShow: true, // 앱바유무
            appBarBackground: 'mainColor', // 앱바 배경색
            goBackBtnShow: true, // 뒤로가기 버튼 유무
            appBarBtnColor: 'backgroundColor', // 앱바 버튼 색(뒤로가기, 우측버튼 모두)
            additionalBtn: null, // 우측 추가적인 버튼(컴포넌트명)
            mainBackground: 'mainColor', // 메인 배경색
            bottomNaviShow: false, //바텀바 유무
            bottomNaviTopCornerColor: null // 바텀바 상단 좌우측 모서리 색
        }
    },
    CASH_HISTORY_LIST:{ // 캐시 내역
        name:'CashHistoryList', // 라우팅이름
        file:'CashHistoryList', // 파일명
        path:'/cashhistorylist', // 라우팅 경우
        menu: BOTTOM_MENU.MY_PAGE, // 소속 바텀메뉴
        layoutOptions:{
            appBarShow: true, // 앱바유무
            appBarBackground: 'mainColor', // 앱바 배경색
            goBackBtnShow: true, // 뒤로가기 버튼 유무
            appBarBtnColor: 'backgroundColor', // 앱바 버튼 색(뒤로가기, 우측버튼 모두)
            additionalBtn: 'Home', // 우측 추가적인 버튼(컴포넌트명)
            mainBackground: 'mainColor', // 메인 배경색
            bottomNaviShow: false, //바텀바 유무
            bottomNaviTopCornerColor: null // 바텀바 상단 좌우측 모서리 색
        }
    },
    PUSH_LIST:{ // 알림리스트
        name:'PushList', // 라우팅이름
        file:'PushList', // 파일명
        path:'/pushlist', // 라우팅 경우
        menu: BOTTOM_MENU.HOME, // 소속 바텀메뉴
        layoutOptions:{
            appBarShow: true, // 앱바유무
            appBarBackground: 'mainColor', // 앱바 배경색
            goBackBtnShow: true, // 뒤로가기 버튼 유무
            appBarBtnColor: 'backgroundColor', // 앱바 버튼 색(뒤로가기, 우측버튼 모두)
            additionalBtn: null, // 우측 추가적인 버튼(컴포넌트명)
            mainBackground: 'mainColor', // 메인 배경색
            bottomNaviShow: false, //바텀바 유무
            bottomNaviTopCornerColor: null // 바텀바 상단 좌우측 모서리 색
        }
    },
    MEMBER_CARD_LIST:{ // 회원카드 목록
        name:'MemberCardList', // 라우팅이름
        file:'MemberCardList', // 파일명
        path:'/membercardlist', // 라우팅 경우
        menu: BOTTOM_MENU.MY_PAGE, // 소속 바텀메뉴
        layoutOptions:{
            appBarShow: true, // 앱바유무
            appBarBackground: 'mainColor', // 앱바 배경색
            goBackBtnShow: true, // 뒤로가기 버튼 유무
            appBarBtnColor: 'backgroundColor', // 앱바 버튼 색(뒤로가기, 우측버튼 모두)
            additionalBtn: null, // 우측 추가적인 버튼(컴포넌트명)
            mainBackground: 'mainColor', // 메인 배경색
            bottomNaviShow: false, //바텀바 유무
            bottomNaviTopCornerColor: null // 바텀바 상단 좌우측 모서리 색
        }
    },
    MEMBER_CARD_REGI:{ // 회원카드 등록
        name:'MemberCardRegi', // 라우팅이름
        file:'MemberCardRegi', // 파일명
        path:'/membercardregi', // 라우팅 경우
        menu: BOTTOM_MENU.MY_PAGE, // 소속 바텀메뉴
        layoutOptions:{
            appBarShow: true, // 앱바유무
            appBarBackground: 'mainColor', // 앱바 배경색
            goBackBtnShow: true, // 뒤로가기 버튼 유무
            appBarBtnColor: 'backgroundColor', // 앱바 버튼 색(뒤로가기, 우측버튼 모두)
            additionalBtn: null, // 우측 추가적인 버튼(컴포넌트명)
            mainBackground: 'mainColor', // 메인 배경색
            bottomNaviShow: false, //바텀바 유무
            bottomNaviTopCornerColor: null // 바텀바 상단 좌우측 모서리 색
        }
    },
    POLICY:{ // 약관 페이지
        name:'Policy', // 라우팅이름
        file:'Policy', // 파일명
        path:'/policy/:policy', // 라우팅 경우
        menu: BOTTOM_MENU.MY_PAGE, // 소속 바텀메뉴
        layoutOptions:{
            appBarShow: true, // 앱바유무
            appBarBackground: 'mainColor', // 앱바 배경색
            goBackBtnShow: true, // 뒤로가기 버튼 유무
            appBarBtnColor: 'backgroundColor', // 앱바 버튼 색(뒤로가기, 우측버튼 모두)
            additionalBtn:  null, // 우측 추가적인 버튼(컴포넌트명)
            mainBackground: 'mainColor', // 메인 배경색
            bottomNaviShow: false, //바텀바 유무
            bottomNaviTopCornerColor: 'backgroundColor' // 바텀바 상단 좌우측 모서리 색
        }
    },
    WITHDRAWAL:{ // 약관 페이지
        name:'Withdrawal', // 라우팅이름
        file:'Withdrawal', // 파일명
        path:'/withdrawal', // 라우팅 경우
        menu: BOTTOM_MENU.MY_PAGE, // 소속 바텀메뉴
        layoutOptions:{
            appBarShow: true, // 앱바유무
            appBarBackground: 'mainColor', // 앱바 배경색
            goBackBtnShow: true, // 뒤로가기 버튼 유무
            appBarBtnColor: 'backgroundColor', // 앱바 버튼 색(뒤로가기, 우측버튼 모두)
            additionalBtn:  null, // 우측 추가적인 버튼(컴포넌트명)
            mainBackground: 'mainColor', // 메인 배경색
            bottomNaviShow: false, //바텀바 유무
            bottomNaviTopCornerColor: 'backgroundColor' // 바텀바 상단 좌우측 모서리 색
        }
    },

    /// 비회원 페이지 설정 ///
    NONMEMBER_MAIN:{
        name:'NonMember',
        file:'nonMember/Main',
        path:'nonmember',
        menu:'',
        layoutOptions:{
            appBarShow: false, // 앱바유무
            appBarBackground: '', // 앱바 배경색
            goBackBtnShow: false, // 뒤로가기 버튼 유무
            appBarBtnColor: '', // 앱바 버튼 색(뒤로가기, 우측버튼 모두)
            additionalBtn:  null, // 우측 추가적인 버튼(컴포넌트명)
            mainBackground: 'backgroundColor', // 메인 배경색
            bottomNaviShow: false, //바텀바 유무
            bottomNaviTopCornerColor: '' // 바텀바 상단 좌우측 모서리 색
        }
    },
    NONMEMBER_CHARGING_INFO:{
        name:'NonMemberChargingInfo',
        file:'nonMember/ChargingInfo',
        path:'nonmember/charginginfo/:transaction_id',
        menu:'',
        layoutOptions:{
            appBarShow: false, // 앱바유무
            appBarBackground: '', // 앱바 배경색
            goBackBtnShow: false, // 뒤로가기 버튼 유무
            appBarBtnColor: '', // 앱바 버튼 색(뒤로가기, 우측버튼 모두)
            additionalBtn:  null, // 우측 추가적인 버튼(컴포넌트명)
            mainBackground: 'backgroundColor', // 메인 배경색
            bottomNaviShow: false, //바텀바 유무
            bottomNaviTopCornerColor: '' // 바텀바 상단 좌우측 모서리 색
        }
    },
})

module.exports = {
    BOTTOM_MENU,
    PAGES
}