<template>
    <v-card :to="to" class="pa-4 mb-2" flat tile :style="`border: 1px solid ${$vuetify.theme.currentTheme.faintColor} !important`">
        <slot/>
    </v-card>
</template>

<script>
export default {
    name: 'ListViewItem',
    props:{
        to:{
            default: ''
        }
    }
}
</script>