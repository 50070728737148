<template>
    <!-- <v-container v-if="layoutOptions.bottomNaviShow" :style="`position:fixed;bottom:0;background-color:transparent !important;`"> -->
    <v-container v-if="layoutOptions.bottomNaviShow" :style="`position:fixed;bottom:0;background:${$vuetify.theme.currentTheme[layoutOptions.bottomNaviTopCornerColor]};min-width:100%;`">
      <v-bottom-navigation ref="bottomNavigation" 
        :value="menuProps" app Hide-on-scroll color="mainColor" elevation="1" class="rounded-t-xl" style="position: relative;" min-width="100%">
          <v-btn :value="$bottomMenu.HOME" plain @click="$function.movePage($pages.HOME.name)">
            <!-- <span>홈</span> -->
            <v-icon>mdi-home</v-icon>
          </v-btn>

          <v-btn :value="$bottomMenu.SEARCH_PLACE" plain @click="$function.movePage($pages.SEARCH_PLACE.name)">
            <!-- <span>충전소찾기</span> -->
            <v-icon>mdi-map-search-outline</v-icon>
          </v-btn>

          <v-btn :value="$bottomMenu.CASH" plain @click="$function.movePage($pages.CASH.name)">
            <!-- <span>캐시</span> -->
            <v-icon>mdi-alpha-c-circle</v-icon>
          </v-btn>

          <v-btn :value="$bottomMenu.MY_PAGE" plain @click="$function.movePage($pages.MY_PAGE.name)">
            <!-- <span>마이페이지</span> -->
            <v-icon>mdi-account</v-icon>
          </v-btn>

      </v-bottom-navigation>
    </v-container>
</template>

<script>
  export default {
    name: 'BottomNavigation',
    props:{
      menuProps:{
        type:String
      },
      layoutOptions:{
        type:Object
      }
    }
  }
</script>