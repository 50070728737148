import axios from 'axios'
import { alert, movePage } from './function'
import store from '@/store'

const instance = axios.create({
    baseURL:'/api'
})

// 요청 타임아웃
instance.defaults.timeout = 10000

// 요청 인터셉터
instance.interceptors.request.use(
    async config => {
        // 요청 직전 수행할 로직
        config.headers['lang'] = store.get('app/lang') // 언어
        return config
    },
    async error => {
        // 요청 에러 시
        console.log(error)
        alert(`네트워크가 불안정합니다. 잠시후 다시 시도해주세요`, 'warning')
        return error.response.data
    }
)

// 응답 인터셉터
instance.interceptors.response.use(
    async response => {
        // 응답에 대한 로직
        const res = response.data
        return res
    },
    async error => {
        // 응답 실패 로직
        console.log('---------- axios response error ----------')
        console.log(error)
        if(!error.response || !error.response.status){ // ERR_NETWORK의 경우 status없음
            return alert('네트워크가 불안정합니다. 잠시후 다시 시도해주세요', 'warning')
        }
        switch(error.response.status){
            case 400: // bad request
                /**
                 * 
                 */
                alert(error.response.data.detail, 'warning')
                break
            case 401: // unauthorizaed 
                /**
                 * 인증, 즉 로그인이 되지 않은 상태로 401상태의 경우 alert과 함께 페이지 이동을 한다
                 */
                alert('로그인이 필요한 서비스입니다')
                movePage('Login')
                break
            case 403: // forbidden
                break
            case 404: // not found
                /**
                 * 클라이언트가 요청한 자원이 존재하지 않는경우 1. 경로, 2. 데이터
                 * 클라이언트의 요청, 이벤트(사용자의 행동)가 있으므로 사용자에게 alert으로 상태룰 전달한다.
                 * 심각한 문제가 아니므로 warning
                 */
                if (error.request.responseURL.includes('fileDown')) { // 파일다운로드시 responseType이 arraybuffer라서 변환작업이 필요함
                    const enc = new TextDecoder('utf-8')
                    const decodeRes = JSON.parse(enc.decode(error.response.data))

                    alert(decodeRes.detail, 'warning')
                    return decodeRes
                } else { 
                    alert(error.response.data.detail, 'warning')
                    break
                }
            case 409: // conflict
                /**
                 * 클라이언트의 요청이 서버의 상태와 충돌이 발생한 경우
                 * 예를 들어 회원가입 시 중복된 아이디를 요청할 경우
                 * 충전기를 사용할 경우에도 사용이 불가할 경우 conflict (누가 사용중이거나 문제가 있어 사용이 불가하거나)
                 */
                alert(error.response.data.detail, 'warning')
                break
            case 429: // too many request
                break
            default:
                break
        }

        return error.response.data
    }
)

export default instance