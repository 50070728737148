<template>
    <gmap-map 
        ref="gmap"
        :options="mapOptions"
        :center="position"
        :zoom="14"
        style='width:100%;height:300px;'
    >
        <gmap-marker
            id="button-1"
            :position="position"
        />

    </gmap-map>
</template>

<script>
export default {
    name: 'GoogleMapComponent',
    props: {
        mapOptions: { 
            type: Object,
            default :()=> {
                return {
                    clickableIcons : false, 
                    gestureHandling : 'none',
                    zoomControl: false,
                    mapTypeControl: false,
                    scaleControl: false,
                    streetViewControl: false,  //거리를 사람입장에서 보기 옵션
                    rotateControl: false,
                    fullscreenControl: false,
                    disableDefaultUi: false,
                    keyboardShortcuts: false,
                }
            }
        },
        position:{
            type: Object,
            required: true
        },
        height: {
            type:Number,
            default: 300
        },
    },
}
</script>