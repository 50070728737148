import { make } from 'vuex-pathify'
import i18n from '@/util/i18n'

// app/ 경로 아래에 사용할 상태변수들 정의
const state = {
    frontNumberList: ['010','011','016','017','019'], // 핸드폰번호 앞자리
    rules: { // 입력 룰
        required: (value) => !value ? i18n.t('store.account.required') : true, // 필수 입력
        pwdChk: (value, pwd) => value != pwd ? i18n.t('store.account.pwdChk') : true, // 비밀번호 확인 체크
        pwdRegex: (value) => ! /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,15}$/.test(value) ? i18n.t('store.account.pwdRegex') : true, // 비밀번호 정규식
        nameRegex: (value) => ! /^[가-힣a-zA-Z]{2,}$/.test(value) ? i18n.t('store.account.nameRegex') : true, // 이름 정규식
        phoneNumberRegex: (value) => ! /^[0-9]{8}$/.test(value) ? i18n.t('store.account.phoneNumberRegex') : true, // 핸드폰 번호 뒷자리 정규식
        checkboxRequired: (value) => !value ? i18n.t('store.account.checkboxRequired') : true, // 체크박스 필수체크,
        comboboxRequired: (value) => !value ? i18n.t('store.account.comboboxRequired') : true,
    },
    emailAddressList: ['직접입력', 'naver.com', 'hanmail.net', 'gmail.com', 'nate.com', 'hotmail.com'], // 기존 emailAddress
    carList : [
        { text : "없음", value : 0, order : 0 },{ text : '코나', value : 4 , order : 1},{ text : "볼트", value : 5, order : 3 },{ text : '아이오닉', value : 6, order : 2},{ text : '그 외 전기차', value : 22, order : 4}
    ],
}

const mutations = make.mutations(state)

const actions = {
    ...make.actions(state),
}

const getters = {}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}

