<template>
  <v-app-bar
    id="default-app-bar" app fixed flat
    v-if="layoutOptions.appBarShow"
    :color="layoutOptions.appBarBackground"
  >
    <v-app-bar-nav-icon
      v-if="layoutOptions.goBackBtnShow">
      <v-btn icon @click="back" :ripple="false">
        <v-icon :color="layoutOptions.appBarBtnColor">
          mdi-arrow-left
        </v-icon>
      </v-btn>
    </v-app-bar-nav-icon>
      <component v-if="layoutOptions.additionalBtn" :is="component" :additionalBtnColor="layoutOptions.appBarBtnColor"
        style="position:absolute; right: 18px;"/>
  </v-app-bar>
</template>

<script>
  export default {
    name: 'AppBar',
    data(){
      return{
        component: null
      }
    },
    props:{
      layoutOptions:{
        type:Object
      }
    },
    methods:{
      back(){
        this.$router.go(-1)
      }
    },
    watch : {
      'layoutOptions.additionalBtn' : {
        handler(val) {
          this.component = () => import(`@/components/additionalButton/${val}`)
        },
        immediate: true,
      }
    }
  }
</script>

<style scoped>
/* #default-app-bar{
  
  padding-left: constant(safe-area-inset-left);
  padding-right: constant(safe-area-inset-right);

  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
} */
</style>