<template>
    <common-text-field :title="$t('EmailField.email')" :placeholder="$t('EmailField.emailPlaceHolder')" :vModel.sync="email.id" :errorMessages="errorMessages.id" >

        <v-col cols="1" class="text-center" slot="prependSlot" order="1">@</v-col>
        <v-col cols="6" slot="appendSlot" order="2">
            <v-select
                v-model="domain"
                :items="emailAddressList"
                @change="val => testFunc(val)"
                dense
                class="ma-0 mr-2"
                :placeholder="$t('EmailField.select')"
                :error-messages="errorMessages.address"
                clearable
            ></v-select>
        </v-col>
        <common-text-field v-if="showDomainInput" slot="additionalSlot" title="" :placeholder="$t('EmailField.emailAddressPlaceHolder')" :vModel.sync="email.address" :errorMessages="errorMessages.domain"></common-text-field>
    </common-text-field>
</template>

<script>

import { get } from 'vuex-pathify'

export default {
    name: 'EmailField',
    data(){
        return{
            email:{ // about email
                id: '', // 이메일 아이디  (기존 emailId)
                address: null, // 이메일 주소 (기존 emailAddress)
            },
            errorMessages: { // 이메일이 필수는 아니나 이메일 아이디나 이메일 주소 입력 시 둘 다 입력하도록 유도하기 위해
                id: '',
                address: '',
                domain: ''
            },
            showDomainInput: false,
            domain: null
        }
    },
    props: {
        vModel:{ // v-model
            default: ''
        },
    },
    methods:{
        testFunc(val) {
            console.log(val, 'value_test')

            if (val === '직접입력') {
                this.showDomainInput = true
                this.email.address = ''
            } else {
                this.showDomainInput = false
                this.email.address = val
            }
        },
        vModelReturn(){
            this.$emit('update:vModel', `${this.email.id}@${this.email.address}`)
        },
    },
    watch:{
        email:{
            handler(val){
                // console.log(val)
                this.errorMessages.id = this.errorMessages.address = '' // 에러메세지 초기화
                if (this.showDomainInput) {
                    if (val.address == null || val.address == '') {
                        this.errorMessages.domain = this.$i18n.t('EmailField.inputDomainMsg')
                    } else {
                        let regExp = /([a-zA-Z0-9]+\.)+[a-zA-Z0-9]{2,8}$/
                        if (!regExp.test(val.address)) {
                            this.errorMessages.domain = this.$i18n.t('EmailField.inputDomainValidMsg')
                        } else {
                            this.errorMessages.domain = ''
                        }
                    }
                }
                if(val.id != '' && val.address != null){
                    this.vModelReturn()
                }else{
                    this.$emit('update:vModel', '')
                    if(val.id == '' && val.address != null){
                        this.errorMessages.id = this.$i18n.t('EmailField.inputIdMsg')
                    }else if(val.id != '' && val.address == null){
                        this.errorMessages.address = this.$i18n.t('EmailField.selectAddressMsg')
                    }
                }
            },
            deep: true
        },
        vModel: {
            handler(val){
                if(val.includes('@')){
                    this.email = {id : val.split('@')[0], address : val.split('@')[1]}
                }
            }
        }
    },
    computed:{
        ...get('AccountConstant',['emailAddressList'])
    }
}
</script>