<template>
    <v-list width="100%" height="100%" class="pa-0" color="subBackgroundColor">
        <v-list-item-group class="pa-2">
            <slot />
        </v-list-item-group>
    </v-list>
</template>

<script>
export default {
    name: 'ListViewContainer'
}
</script>