import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { sync } from 'vuex-router-sync'
import axios from 'axios'
import './plugins'
import * as globalFunction from './util/function'
import * as javaScriptBridge from './util/javaScriptBridge'
import * as api from './util/api'
import VueCookies from 'vue-cookies'
import { BOTTOM_MENU, PAGES } from '@/util/pageSettings'
import i18n from './util/i18n'

import '@mdi/font/css/materialdesignicons.min.css'

Vue.config.productionTip = false
Vue.prototype.$axios = axios
Vue.prototype.$cookies = VueCookies

sync(store, router)

Vue.prototype.$function = globalFunction // 글로벌 함수 // 사용시에 this.$function.함수명으로 사용가능(ex : this.$function.alert(params))
Vue.prototype.$api = api
Vue.prototype.$bridge = javaScriptBridge
Vue.prototype.$constant = {} // vue 시작 시 백엔드에 저장된 contant를 요청하여 저장함
Vue.prototype.$bottomMenu = BOTTOM_MENU // 바텀 내비
Vue.prototype.$pages = PAGES // 라우팅 페이지들

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
