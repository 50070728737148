import { isIosApp, isAndroidApp } from '../util/function'


export function RequestLoginInfo() { 
    if (isAndroidApp()) {
        return window.android.RequestLoginInfo();
    } else if (isIosApp()) {
        return window.webkit.messageHandlers.RequestLoginInfo.postMessage("")
    } 
}

export function SaveLoginInfo(uid, autoCheck, ukey, utoken) { 
    if (isAndroidApp()) {
        return window.android.SaveLoginInfo(uid, autoCheck, ukey, utoken)
    } else if (isIosApp()) {
        ukey = (ukey != "" ? ukey.toString() : "")
        autoCheck = autoCheck.toString()
        return window.webkit.messageHandlers.SaveLoginInfo.postMessage({uid: uid, autoCheck: autoCheck, ukey: ukey, utoken: utoken});
    } 
}

export function RequestFirebaseToken() { 
    if (isAndroidApp()) {
        return window.android.RequestFirebaseToken()
    } else if (isIosApp()) {
        return window.webkit.messageHandlers.RequestFirebaseToken.postMessage("")
    } 
}

export function PlaySound(soundStr) { // cable_connect, charge_start, charge_end
    if (isAndroidApp()) {
        return window.android.PlaySound(soundStr)
    } else if (isIosApp()) {
        return window.webkit.messageHandlers.PlaySound.postMessage(soundStr)
    } 
}

export function RequestGps(reqPermission, reqSetting = true) {
    if (isAndroidApp()) {
        return window.android.RequestGps(reqPermission, reqSetting)
    } else if (isIosApp()) {
        return window.webkit.messageHandlers.RequestGps.postMessage({reqPermission: reqPermission, reqSetting: reqSetting})
    } 
}

export function getBase64FromBlobData(base64Data, originalname) { 
    if (isAndroidApp()) {
        return window.android.getBase64FromBlobData(base64Data, originalname)
    } else if (isIosApp()) { 
        return window.webkit.messageHandlers.getBase64FromBlobData.postMessage({base64Data: base64Data, originalname: originalname})
    } 
}