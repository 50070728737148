import store from "@/store";
import Vue from "vue";
import * as VueGoogleMaps from 'vue2-google-maps';

let apiKey = "AIzaSyCDyvTHVQ9a_dw3Om8ydkeJd3uCAL9sytI"	//구글에서 발급받은 키 입력

Vue.use(VueGoogleMaps, {
    load: {
        key: apiKey,
        language : store.get('app/lang'),	//option
        libraries: 'places', // This is required if you use the Autocomplete plugin
    },
})

export default apiKey;